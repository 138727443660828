import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import _ from "loadsh";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { formatForId } from "../Utils/Lang/IntlHelper";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "row",
    },
    data: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
    },
    column: {
        display: "flex",
        flexDirection: "column",
        " &.label": {
            flexGrow: 1,
        },
    },
    columnHead: {
        color: "whitesmoke",
        " &.property": {
            color: "#ccc",
        },
        backgroundColor: "#18202c",
        position: "sticky",
        top: 0,
        // textAlign: "center",
    },
    cellBase: {
        paddingTop: "0.15rem",
        paddingBottom: "0.15rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        " &.value": {
            textAlign: "center",
        },
        " &.selected": {
            color: "black",
        },
        marginBottom: 1.5,
        marginTop: 1.5,
    },
    singleCell: {
        whiteSpace: "nowrap",
        height: "1.5rem",
    },
    doubleCell: {
        height: "3rem",
    },
    cell: {
        cursor: "pointer",
    },
    cellDisabled: {},
});

export function PropertyCell({ className, label, color, selected, onClick, width, tooltip }) {
    let style;
    if (selected) {
        className = clsx(className, "selected");
        style = { backgroundColor: color };
    } else {
        style = { color: color };
    }

    if (width) {
        style.width = width;
    }

    return (
        <Tooltip title={tooltip ? tooltip : ""}>
            <div className={className} style={style} onClick={onClick}>
                {_.isEmpty(label) ? <>&nbsp;</> : label}
            </div>
        </Tooltip>
    );
}

export function PropertySelectionList({ fields, columns, localizationContext, data, isSelected, onSelected, tooltipLocalizationContext, doubleHeightFieldsIds = [] }) {
    const styles = useStyles();
    const intl = useIntl();
    const columnHeader = (id) => formatForId(intl, `${localizationContext}.label.${id}`);
    const propertyLabel = (id) => formatForId(intl, `${localizationContext}.${id}`);
    const tooltipLabel = (id) => (tooltipLocalizationContext ? formatForId(intl, `${tooltipLocalizationContext}.${id}`) : undefined);
    return (
        <div className={styles.root}>
            <div className={clsx(styles.column, "label")}>
                <div className={clsx(styles.cell, styles.columnHead, "property")}>{columnHeader("name")}</div>
                {_.map(fields, (field) => {
                    const selectedCell = isSelected(field.id);
                    const isDoubleCell = doubleHeightFieldsIds.includes(field.id);
                    return (
                        <PropertyCell
                            key={field.id}
                            className={clsx(styles.cellBase, field.disabled ? styles.cellDisabled : styles.cell, isDoubleCell ? styles.doubleCell : styles.singleCell)}
                            color={field.color}
                            label={propertyLabel(field.id)}
                            selected={selectedCell}
                            onClick={
                                !field.disabled && onSelected
                                    ? function () {
                                          onSelected(field.id);
                                      }
                                    : () => {}
                            }
                            tooltip={tooltipLabel(field.id)}
                        />
                    );
                })}
            </div>
            {_.map(columns, (column) => (
                <div key={column.id} className={styles.column}>
                    <div className={clsx(styles.cell, styles.columnHead, "value")}>{columnHeader(column.id)}</div>
                    {_.map(fields, (field) => {
                        const selectedCell = isSelected(field.id);
                        const isDoubleCell = doubleHeightFieldsIds.includes(field.id);

                        const dataColumn = data[column.id];
                        let dataValue = typeof dataColumn === "object" ? dataColumn[field.id] : null;
                        if (typeof dataValue === "undefined" || isNaN(dataValue)) {
                            dataValue = null;
                        }

                        const formattedValue = dataValue !== null && _.isFunction(field.valueFormatter) ? field.valueFormatter(dataValue) : dataValue;

                        return (
                            <PropertyCell
                                key={field.id}
                                className={clsx(styles.cellBase, field.disabled ? styles.cellDisabled : styles.cell, "value", isDoubleCell ? styles.doubleCell : styles.singleCell)}
                                color={field.color}
                                label={formattedValue}
                                selected={selectedCell}
                                onClick={
                                    !field.disabled && onSelected
                                        ? function () {
                                              onSelected(field.id);
                                          }
                                        : () => {}
                                }
                                width={column.width}
                            />
                        );
                    })}
                </div>
            ))}
        </div>
    );
}
