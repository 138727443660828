import React, { useEffect, useMemo, useRef, useState } from "react";
import L from "leaflet";
import { Polyline, Popup, useMap, useMapEvents } from "react-leaflet";
import { useTheme } from "@material-ui/core";

export default function CustomPopup({ children, initialPosition, popupPos, open, onPositionChange, onDragStart, onDragEnd, markerRef, popupDimensions, draggable, offset, positionRefresh, ...props }) {
    const ref = useRef();
    const theme = useTheme();
    const map = useMap();

    const [zoom, setZoom] = useState(0);

    useMapEvents({
        zoomend: (evt) => {
            setZoom(evt.target._zoom);
        },
    });

    const latLngToPoint = (position) => {
        return map.latLngToLayerPoint(position);
    };

    const pointToLatLng = (position) => {
        return map.layerPointToLatLng(position);
    };

    const setPosition = (popup, position) => {
        const pos = pointToLatLng(position);
        onPositionChange(pos);
        popup._source._popup.setLatLng(pos);
    };

    const calculateLineEnd = (popupPosition, popupDimensions) => {
        const point = latLngToPoint(popupPosition);
        const x = point.x + offset[0];
        const y = point.y + offset[1];
        if (popupDimensions) {
            return pointToLatLng({ x: x + popupDimensions.width / 2, y: y - popupDimensions.height / 2 });
        } else {
            return pointToLatLng({ x, y: y - 100 });
        }
    };

    const calculateLineStart = () => {
        const point = latLngToPoint(markerRef.current?._latlng ? [markerRef.current._latlng.lat, markerRef.current._latlng.lng] : initialPosition);
        return pointToLatLng({ x: point.x, y: point.y });
    };

    const makeDraggable = (popup) => {
        const pos = latLngToPoint(popupPos ? popupPos : popup._source.getLatLng());

        L.DomUtil.setPosition(popup._source._popup._wrapper.parentNode, pos);
        const draggable = new L.Draggable(popup._source._popup._container, popup._source._popup._wrapper);
        draggable.enable();

        onPositionChange(popupPos ? popupPos : popup._source.getLatLng());

        draggable.on("dragstart", function () {
            if (onDragStart) {
                onDragStart();
            }
        });

        draggable.on("dragend", function () {
            setPosition(popup, this._newPos);
            if (onDragEnd) {
                onDragEnd();
            }
        });

        draggable.on("drag", function () {
            setPosition(popup, this._newPos);
        });
    };

    useEffect(() => {
        if (ref.current && ref.current._source && ref.current._source._popup) {
            ref.current._source._popup.setLatLng(popupPos);
        }
    }, [popupPos, open]);

    useEffect(() => {
        setTimeout(() => {
            if (open) {
                if (draggable) {
                    makeDraggable(ref.current);
                }
            } else {
                ref.current._source.closePopup();
            }
        }, 1);
    }, [open, ref.current]);

    useEffect(() => {
        if (ref.current && ref.current._source && ref.current._source._popup) {
            ref.current._source._popup.setLatLng(popupPos);
        }
    }, [positionRefresh]);

    useEffect(() => {
        if (ref.current && ref.current._source && ref.current._source._popup) {
            ref.current._source._popup.options.offset = offset;
            ref.current._source._popup.update();
        }
    }, [offset]);

    const polyLinePos = useMemo(() => (open ? [calculateLineStart(), calculateLineEnd(popupPos, popupDimensions)] : null), [popupPos, initialPosition, open, popupDimensions, zoom, offset]);

    return (
        <>
            <Popup {...props} ref={ref} open={open} offset={offset}>
                {children}
            </Popup>
            {draggable && polyLinePos && <Polyline color={"#4D545D"} positions={polyLinePos} weight={2.4} />}
        </>
    );
}
