import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Tooltip, withStyles } from "@material-ui/core";
import _ from "loadsh";

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : "rgba(97, 97, 97, 0.9)"),
        maxWidth: 800,
    },
}))(Tooltip);

const useStyles = makeStyles((props) => ({
    colHead: {
        textAlign: "left",
        paddingRight: 10,
    },
    colValue: {
        whiteSpace: "nowrap",
    },
    line: {
        borderBottom: "1px solid gray",
        height: 5,
        marginBottom: 2,
        maxWidth: 800,
    },
}));

function ColHead({ children, ...props }) {
    const classes = useStyles();
    return (
        <th className={classes.colHead} {...props}>
            {children}
        </th>
    );
}

function ColValue({ children, ...props }) {
    const classes = useStyles();

    return (
        <td className={classes.colValue} {...props}>
            {children}
        </td>
    );
}

function TooltipTableLocal({ items }) {
    const classes = useStyles();

    return (
        <tbody>
            {items.map((row, index) => (
                <>
                    <tr key={row.name}>
                        <ColHead>{row.name}</ColHead>
                        <ColValue>{row.value}</ColValue>
                    </tr>
                    {row.hasLine && (
                        <tr key={`${row.name}-line`}>
                            <ColValue colSpan={2}>
                                <div className={classes.line}></div>
                            </ColValue>
                        </tr>
                    )}
                </>
            ))}
        </tbody>
    );
}

export function TooltipTable({ children, items }) {
    return <CustomTooltip title={<TooltipTableLocal items={items} />}>{children}</CustomTooltip>;
}
