import { makeStyles } from "@material-ui/styles";
import { Tooltip, Typography, withStyles } from "@material-ui/core";
import { Valign } from "../../../Components/Flex";
import React from "react";
import { useEndDeviceData } from "../../../Utils/Data/actions/RnrData";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import { useIntl } from "react-intl";
import { useDeviceState } from "../../../Utils/Data/hooks/server";
import { CommunicationStateItems } from "../../../Components/DeviceStateIcon";
import _ from "lodash";
import { alpha } from "@material-ui/core/styles";
import { useViewCustomConfig } from "../../../Utils/Data/hooks/deviceDataView";

const useStyles = makeStyles((props) => ({
    colHead: {
        textAlign: "left",
        paddingRight: 10,
    },
    colValue: {
        whiteSpace: "nowrap",
    },
    line: {
        borderBottom: "1px solid gray",
        height: 5,
        marginBottom: 2,
        maxWidth: 800,
    },
}));

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : "rgba(97, 97, 97, 0.9)"),
        maxWidth: 800,
    },
}))(Tooltip);

export function RnrViewTooltip({ tooltipColor, children, title }) {
    return (
        <CustomTooltip backgroundColor={tooltipColor} title={title}>
            {children}
        </CustomTooltip>
    );
}

export function formatRnrState(intl, state) {
    const reason = state.reason ? `: ${state.reason}` : "";
    const formatted = formatForId(intl, `RnrDeviceStateTooltip.state.${state.id}`, { reason });
    if (formatted === `RnrDeviceStateTooltip.state.${state.id}`) {
        return state.id;
    } else {
        return formatted;
    }
}

function StateItem({ state, labelKey, showWhenEmpty }) {
    const classes = useStyles();
    const intl = useIntl();

    if (!showWhenEmpty && _.isEmpty(state)) {
        return null;
    }
    return (
        <tr>
            <ColHead>{formatForId(intl, labelKey)}</ColHead>
            <ColValue>
                {_.isEmpty(state)
                    ? formatForId(intl, "RnrDeviceStateTooltip.NA")
                    : state
                          .map((state) => {
                              formatRnrState(intl, state);
                          })
                          .join(", ")}
            </ColValue>
        </tr>
    );
}

function StateItems({ state }) {
    return (
        <>
            <StateItem state={state?.activeErrors} labelKey="RnrDeviceStateTooltip.error" showWhenEmpty />
            <StateItem state={state?.activeWarnings} labelKey="RnrDeviceStateTooltip.warning" showWhenEmpty />
            <StateItem state={state?.activeInfos} labelKey="RnrDeviceStateTooltip.info" showWhenEmpty />
        </>
    );
}

function ColHead({ children, ...props }) {
    const classes = useStyles();
    return (
        <th className={classes.colHead} {...props}>
            {children}
        </th>
    );
}

function ColValue({ children, ...props }) {
    const classes = useStyles();

    return (
        <td className={classes.colValue} {...props}>
            {children}
        </td>
    );
}

function IoDevice({ ioDevice, trafficStatesData }) {
    const ioDeviceState = useEndDeviceData(ioDevice, trafficStatesData);
    const classes = useStyles();
    const intl = useIntl();

    return (
        <>
            <tr>
                <ColHead colSpan={2}>{ioDevice?.endDevice?.display_name ? ioDevice.endDevice.display_name : ioDevice.id}</ColHead>
            </tr>
            <tr>
                <ColHead>{formatForId(intl, "RnrDeviceStateTooltip.ioValue")}</ColHead>
                <ColValue>{ioDeviceState?.endDeviceState?.current_display ? ioDeviceState?.endDeviceState?.current_display?.symbols?.[0] : "N/A"}</ColValue>
            </tr>
            <StateItem state={ioDeviceState?.endDeviceState?.activeErrors} labelKey="RnrDeviceStateTooltip.error" showWhenEmpty={false} />
            <StateItem state={ioDeviceState?.endDeviceState?.activeWarnings} labelKey="RnrDeviceStateTooltip.warning" showWhenEmpty={false} />
            <StateItem state={ioDeviceState?.endDeviceState?.activeInfos} labelKey="RnrDeviceStateTooltip.info" showWhenEmpty={false} />

            <tr>
                <ColValue colSpan={2}>
                    <div className={classes.line}></div>
                </ColValue>
            </tr>
        </>
    );
}

function Position({ position }) {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <>
            <tr>
                <th className={classes.colHead}>{formatForId(intl, "forms.deviceDetail.road")}</th>
                <td> {position?.road}</td>
            </tr>
            <tr>
                <th className={classes.colHead}>{formatForId(intl, "forms.deviceDetail.road_km")}</th>
                <td> {`${position?.road_km?.toString()?.replace(/\./g, ",")} ${formatForId(intl, "value.unit.km")}`}</td>
            </tr>
        </>
    );
}

function TooltipContent({ rnrState, rnrDevice, trafficStatesData }) {
    const classes = useStyles();
    const intl = useIntl();
    const deviceState = useDeviceState(rnrDevice?.systemDevice?.id);

    return (
        <Valign style={{ width: "auto" }} horizontal={"flex-start"}>
            <Typography variant="h6">{rnrDevice.id}</Typography>
            <table>
                <tbody>
                    <tr>
                        <ColHead>{formatForId(intl, "RnrDeviceStateTooltip.version")}</ColHead>
                        <ColValue className={classes.colValue}> {rnrState?.state?.rnr_build_version?.split("git")?.[0]}</ColValue>
                    </tr>
                    <Position position={rnrDevice?.config} />
                    <StateItems state={rnrState} />
                    <CommunicationStateItems deviceState={deviceState} />
                    <tr>
                        <ColValue colSpan={2}>
                            <div className={classes.line}></div>
                        </ColValue>
                    </tr>
                    {rnrDevice?.ioDevices?.map((ioDevice) => (
                        <IoDevice key={ioDevice.id} ioDevice={ioDevice} trafficStatesData={trafficStatesData} />
                    ))}
                </tbody>
            </table>
        </Valign>
    );
}

export const RnrDeviceTooltip = ({ rnrState, rnrDevice, children, trafficStatesData, tooltipColor }) => {
    return (
        <RnrViewTooltip tooltipColor={tooltipColor} title={<TooltipContent rnrDevice={rnrDevice} rnrState={rnrState} trafficStatesData={trafficStatesData} />}>
            {children}
        </RnrViewTooltip>
    );
};

function DeviceStateTooltipContent({ deviceState, device, previewInfo }) {
    const intl = useIntl();

    return (
        <Valign style={{ width: "100%" }} horizontal={"flex-start"}>
            <Typography variant="h6">{device.id}</Typography>
            {deviceState?.hasCriticalError ? (
                <Typography>{formatForId(intl, "RnrDeviceStateTooltip.noData")}</Typography>
            ) : previewInfo === null ? (
                <table>
                    <Position position={device?.position} />
                    <tr>
                        <ColHead>{formatForId(intl, "RnrDeviceStateTooltip.currentSymbols")}</ColHead>
                        <ColValue> {deviceState.current_display?.symbols.join(", ") || formatForId(intl, "RnrDeviceStateTooltip.NA")}</ColValue>
                    </tr>
                    <tr>
                        <ColHead>{formatForId(intl, "RnrDeviceStateTooltip.pendingSymbols")}</ColHead>
                        <ColValue> {deviceState.pending_display?.symbols.join(", ") || formatForId(intl, "RnrDeviceStateTooltip.NA")}</ColValue>
                    </tr>
                    <StateItems state={deviceState} />
                </table>
            ) : (
                <table>
                    <Position position={device?.position} />
                    <tr>
                        <ColHead>{formatForId(intl, "RnrDeviceStateTooltip.previewSymbols")}</ColHead>
                        <ColValue> {previewInfo?.symbols.join(", ") || formatForId(intl, "RnrDeviceStateTooltip.NA")}</ColValue>
                    </tr>
                    <tr>
                        <ColHead>{formatForId(intl, "RnrDeviceStateTooltip.isMandatory")}</ColHead>
                        <ColValue> {previewInfo?.isMandatory ? formatForId(intl, "value.unit.boolean.1") : formatForId(intl, "value.unit.boolean.0")}</ColValue>
                    </tr>
                </table>
            )}
        </Valign>
    );
}

export function DeviceStateTooltip({ deviceState, device, rnrState, children, previewInfo, tooltipColor }) {
    return (
        <RnrViewTooltip tooltipColor={tooltipColor} title={<DeviceStateTooltipContent deviceState={deviceState} device={device} rnrState={rnrState} previewInfo={previewInfo} />}>
            {children}
        </RnrViewTooltip>
    );
}
