import defaultState from "./historyBrowsing.default";

export default function (state = defaultState, action) {
    switch (action.type) {
        case "METEO::HISTORY_BROWSING": {
            return { ...state, ...action.payload };
        }
        default:
            return state;
    }
}
