import { Grid, LinearProgress, useTheme } from "@material-ui/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAlertHistory, useDeviceFieldHistory, useHistoryFilter, useNumericForecast } from "../../../Api";
import { makeStyles } from "@material-ui/core/styles";
import { DataPanel } from "./DataPanel";
import { MeteogramControl } from "./MeteogramControl";
import { MainChart } from "./MainChart";
import { CommonDialog } from "../../CommonDialog";
import { useForecastActive, useZoomActive } from "../../../Utils/Data/hooks/meteogram";
import { useValueInfosNameList } from "../../../Utils/Data/ValueMapper";
import { makeSecurityContext, useHasPermission } from "../../../Utils/Permissions/RequireAnyPermission";
import { useAppAccess, useDeviceState, useHistoryGetData } from "../../../Utils/Data/hooks/server";
import { Last24HoursType, Last36HoursType, Last7DaysType, Last8HoursType } from "../../Forms/DateRangeField/hooks";
import moment from "moment";
import { WarningSeverity } from "../../../Utils/Data/AlertFormatter";
import { setZoomActive } from "../../../Utils/Data/actions/meteogram";
import { useStore } from "react-redux";
import _ from "loadsh";
import { getUserCustomFields } from "../../../Utils/Permissions/PermissionHelper";
import useWindowDimensions from "../../../Utils/Data/hooks/dimensions";

const useStyles = makeStyles((theme) => ({
    chart: {
        height: "100%",
        flexGrow: 1,
        "box-sizing": "border-box",
    },
    content: {
        padding: theme.spacing(2),
    },
    dialogPaper: {
        overflow: "hidden",
    },
}));

export function Meteogram({ device, standalone = false, onClose, rootRef }) {
    const securityContext = useMemo(() => makeSecurityContext(device), [device]);
    const hasPermAlertView = useHasPermission({ permission: "dev__alert_view", securityContext });
    const hasPermStates = useHasPermission({ permission: "dev__states", securityContext });
    const hasPermErrors = useHasPermission({ permission: "dev__errors", securityContext });
    const alertsViewSettings = useMemo(
        () => ({
            hasPermAlertView,
            hasPermStates,
            hasPermErrors,
        }),
        [hasPermAlertView, hasPermStates, hasPermErrors]
    );

    const historyDate = useHistoryGetData();
    const history = useHistoryFilter(Last8HoursType, historyDate);
    const zoomActive = useZoomActive();
    const deviceState = useDeviceState(device.id);
    const { height, width } = useWindowDimensions();
    const theme = useTheme();
    const { custom_props } = useAppAccess();

    const beginTime = useMemo(() => history.value.begin_time, [history]);

    const classes = useStyles();
    const store = useStore();

    const [columns, setColumns] = useState(new Set(["temp", "road_temp", "temp_froz_mea", "visibility", "water_thick", "precip"]));

    const columnsArray = useMemo(() => [...columns], [columns]);

    const allColumns = useValueInfosNameList();
    const columnsString = useMemo(() => "time," + allColumns, [allColumns]);

    const compact = width < 1400 || height < 800;
    const isRowView = width < 1000;

    const autoReload = useMemo(() => {
        return (
            !zoomActive &&
            _.isEmpty(historyDate) &&
            (history.value.type === Last8HoursType || history.value.type === Last24HoursType || history.value.type === Last36HoursType || history.value.type === Last7DaysType)
        );
    }, [zoomActive, history]);

    const { data } = useDeviceFieldHistory(history, true, device.id, columnsString, beginTime, autoReload, deviceState);

    useEffect(() => {
        const userCustomFields = getUserCustomFields();
        let res = userCustomFields.default_graph_fields;

        for (const customProp of custom_props) {
            const customPropsJson = JSON.parse(customProp);
            if (!_.isEmpty(customPropsJson.default_graph_fields)) {
                res = customPropsJson.default_graph_fields;
                break;
            }
        }

        if (!_.isEmpty(res)) {
            setColumns(new Set(res));
        }
    }, []);

    const deviceFilter = useMemo(() => {
        if (device) {
            return [null, null, [device.id]];
        } else return [];
    }, [device]);

    const levelFilter = useMemo(() => WarningSeverity.join(","), []);
    const { data: alertData, loading, refetch: refetchAlerts, history_begin, history_end } = useAlertHistory(true, deviceFilter, levelFilter, history, true, beginTime);

    const forecastActive = useForecastActive();

    const forecastBeginTime = useMemo(() => moment().add(-2, "hours").toDate().getTime(), [history]);

    const { forecastData } = useNumericForecast(history, forecastActive, device, columnsString, forecastBeginTime); //TODO use correct columns

    const hiddenColumns = useMemo(() => {
        const hasValue = new Set();
        const findAllValues = (dataArr) => {
            if (!dataArr) return;

            for (const row of dataArr) {
                for (const column of allColumns) {
                    const value = row[column];

                    if (value !== null && typeof value !== "undefined") {
                        hasValue.add(column);
                    }
                }
            }
        };

        findAllValues(data?.data);

        if (forecastActive) {
            findAllValues(forecastData?.data);
        }

        const result = new Set();
        for (const column of allColumns) {
            if (!hasValue.has(column)) {
                result.add(column);
            }
        }

        return result;
    }, [data, forecastActive, forecastData]);

    const onColumnsChanged = (valueKey, selected) => {
        if (selected) {
            setColumns((prev) => new Set(prev).add(valueKey));
        } else {
            setColumns((prev) => {
                const next = new Set(prev);
                if (next.size > 1) {
                    next.delete(valueKey);
                }
                return next;
            });
        }
    };

    const handleReset = () => {
        history.refresh();
    };

    const [chartHeight, setChartHeight] = useState(null);
    const chartRef = useRef();

    useEffect(() => {
        const { current } = chartRef;
        if (current) {
            setChartHeight(current.getBoundingClientRect().height);

            let timer;
            const handler = () => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    setChartHeight(current.getBoundingClientRect().height);
                }, 250);
            };
            window.addEventListener("resize", handler);

            return () => {
                window.removeEventListener("resize", handler);
                setZoomActive(store, false);
            };
        }
    }, [chartRef, chartRef.current, compact, isRowView]);

    const dataLoading = data.loading || forecastData.loading || loading;

    return (
        <Grid container direction={"column"} style={{ height: "100%" /* overflow: 'hidden'*/ }} wrap={"nowrap"}>
            <Grid item>
                <MeteogramControl handleClose={onClose} device={device} history={history} handleReset={handleReset} standalone={standalone} rootRef={rootRef} />
            </Grid>
            <Grid item style={{ height: "1em" }}>
                {dataLoading && <LinearProgress />}
            </Grid>
            <div style={{ flexGrow: 1, flexBasis: 0 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: isRowView ? "column" : "row",
                        height: "100%",
                        width: "100%",
                        padding: theme.spacing(2),
                    }}
                >
                    <div className={classes.chart} ref={chartRef} style={{ flexBasis: isRowView ? "75%" : "unset" }}>
                        {chartHeight && (
                            <MainChart
                                history={history}
                                data={data.data}
                                forecastData={forecastData.data}
                                columns={columnsArray}
                                alertData={alertData}
                                forecastActive={forecastActive}
                                height={chartHeight}
                                alertsViewSettings={alertsViewSettings}
                                beginTime={beginTime}
                                hiddenColumns={hiddenColumns}
                                compact={compact}
                            />
                        )}
                    </div>

                    <div style={{ flexBasis: isRowView ? "25%" : "unset" }}>
                        <DataPanel
                            columns={columns}
                            onColumnsChanged={onColumnsChanged}
                            forecastActive={forecastActive}
                            forecastData={forecastData.data}
                            hiddenColumns={hiddenColumns}
                            alertsViewSettings={alertsViewSettings}
                            height={chartHeight}
                            device={device}
                            compact={compact}
                            rowView={isRowView}
                        />
                    </div>
                </div>
            </div>
        </Grid>
    );
}

export default function MeteogramDialog({ open, onClose, device, standalone }) {
    const ref = useRef();
    const classes = useStyles();

    return (
        <CommonDialog open={open} onClose={onClose} noButton={true} fullScreen innerRef={ref} paperClass={classes.dialogPaper}>
            <Meteogram standalone={standalone} device={device} onClose={onClose} rootRef={ref} />
        </CommonDialog>
    );
}
