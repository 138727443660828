import React, { useEffect, useRef, useState } from "react";

import "../../index.css";
import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";

import { MapContainer, useMap, useMapEvents, ZoomControl } from "react-leaflet";

import L from "leaflet";
import * as ReactDOMServer from "react-dom/server";
import { useDeviceState } from "../../Utils/Data/hooks/server";
import MarkerClusterGroup from "../../Components/Map/MarkerCluster";
import { useDeviceDataActiveViewDevices, useViewDefaultNiraField } from "../../Utils/Data/hooks/deviceDataView";
import clsx from "clsx";
import { ALERT_DEVICE_OFFLINE, ALERT_FAILURE, getDeviceStateSeverity } from "../../Utils/Data/AlertFormatter";
import { SingleDeviceView } from "../../Components/Map/SingleDeviceView";
import { ClusterTooltipView, TooltipView } from "../../Components/Map/TooltipView";
import { useStore } from "react-redux";
import { setImageOverlayEnabled, setInsufficientNiraZoom, setNiraDataPanelEnabled, setSelectedDataType, setSelectedItem } from "../../Utils/Data/actions/map";
import { BottomDetailView } from "../../Components/Map/BottomDetailView";
import { useIsSelected } from "../../Components/Map/MapUtils";
import { RadarForecastView } from "../../Components/Map/RadarForecastPanel";
import RadarImageOverlay from "../../Components/Map/RadarImageOverlay";
import _ from "loadsh";
import { useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import queryString from "querystring";
import BaseTileLayer, { DarkMap } from "../../Components/Map/BaseTileLayer";
import VectorGrid from "./VectorGrid";
import { None, useNiraData } from "../../Components/NiraApi";
import { useDevicesHidden, useSelectedMapType } from "../../Utils/Data/hooks/map";
import { HideDevicesPanel } from "../../Components/Map/HideDevicesPanel";
import { usePictograms } from "../../Utils/Data/PictogramAlgorithms";
import { useDisabledAggregation } from "../../Utils/Data/hooks/gui";
import { MapRain, MapSnow } from "../../Components/Icons/PictogramIcons";
import { NiraAlertsLayer } from "../../Components/Map/NiraAlertsLayer";
import { useHasOneOfPermission } from "../../Utils/Permissions/RequireAnyPermission";
import { NiraPerms } from "../../Components/Map/NiraDataPanel";
import { isMobile } from "react-device-detect";
import { computeTooltipDirection, CustomClusterIcon, MapItem, MapViewConfigProvider, useMapIconStyles } from "./Map/MapComponents";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
    },
    rootColumn: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    map: {
        flex: 1,
    },
    tooltip: {
        padding: theme.spacing(1),
        margin: -theme.spacing(1),
    },
    pictogramIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.8rem",
    },
}));

function getDeviceStateSeverityCustom(deviceState) {
    const severity = getDeviceStateSeverity(deviceState);

    if (severity === 0) {
        //MIR-83 show failure color in map
        const hasFailure = deviceState?.active_warnings?.find((alert) => alert.level === ALERT_FAILURE);
        if (hasFailure) {
            return ALERT_FAILURE;
        }
    }

    return severity;
}

const createClusterCustomIcon = (cluster, classes) => {
    let worstSeverity = 0;
    let isSelected = false;
    let items = [];
    const handleCluster = (cluster) => {
        if (!_.isEmpty(cluster._markers)) {
            cluster._markers.forEach((item) => {
                const severity = item.options.icon.options.severity;
                const device = item.options.device;

                items.push({ name: device.name, severity });
                isSelected |= item.options.icon.options.isSelected;

                if (severity > 0) {
                    if (severity === ALERT_DEVICE_OFFLINE) {
                        if (worstSeverity === 0) {
                            worstSeverity = severity;
                        }
                    } else if (severity === ALERT_FAILURE) {
                        if (worstSeverity === 0 || worstSeverity === ALERT_DEVICE_OFFLINE) {
                            worstSeverity = severity;
                        }
                    } else if (severity > worstSeverity || worstSeverity === ALERT_DEVICE_OFFLINE || worstSeverity === ALERT_FAILURE) {
                        worstSeverity = severity;
                    }
                }
            });
        }
        if (!_.isEmpty(cluster._childClusters)) {
            cluster._childClusters.forEach((item) => handleCluster(item));
        }
    };

    handleCluster(cluster);

    const tooltipPosition = computeTooltipDirection(cluster._map, cluster.getBounds().getCenter());

    cluster.unbindTooltip();

    items.sort((a, b) => {
        const transformSeverity = (item) => {
            switch (item.severity) {
                case ALERT_DEVICE_OFFLINE:
                    return 0.5;
                case ALERT_FAILURE:
                    return 0.75;
                default:
                    return item.severity;
            }
        };
        return transformSeverity(a) < transformSeverity(b) ? 1 : -1;
    });

    cluster.bindTooltip(ReactDOMServer.renderToString(<ClusterTooltipView devices={items} />), {
        offset: tooltipPosition.offset,
        direction: tooltipPosition.direction,
    });

    return L.divIcon({
        html: ReactDOMServer.renderToString(<CustomClusterIcon cluster={cluster} classes={classes} severity={worstSeverity} isSelected={isSelected} />),
    });
};

function MarkerStateIcon({ classes, isSelected, clusterDisabled, pictograms, severity }) {
    const PrecipState = () => {
        if (!clusterDisabled) {
            return <></>;
        }
        if (pictograms.isRain) {
            return (
                <div className={classes.pictogramIcon}>
                    <MapRain />
                </div>
            );
        } else if (pictograms.isSnow) {
            return (
                <div className={classes.pictogramIcon}>
                    <MapSnow />
                </div>
            );
        } else {
            return <></>;
        }
    };

    return (
        <div
            className={clsx(
                classes.icon,
                clusterDisabled ? classes.clusterDisabledIcon : classes.smallIcon,
                classes[`icon_${severity}`],
                isSelected ? classes.iconSelected : clusterDisabled ? classes.clusterDisabledIconUnselected : classes.iconUnselected
            )}
        >
            <PrecipState />
        </div>
    );
}

function MarkerState({ device, markerRef, classes, clusterGroupRef, tooltipPosition, clusterDisabled }) {
    const deviceState = useDeviceState(device.id);
    const isSelected = useIsSelected(device.id);
    const pictograms = usePictograms(device, deviceState);
    const severity = getDeviceStateSeverityCustom(deviceState);
    const mapIconClasses = useMapIconStyles();

    const iconSize = clusterDisabled ? 20 : 30;
    useEffect(() => {
        const icon = L.divIcon({
            html: ReactDOMServer.renderToString(<MarkerStateIcon severity={severity} classes={mapIconClasses} isSelected={isSelected} clusterDisabled={clusterDisabled} pictograms={pictograms} />),
            iconSize: L.point(iconSize, iconSize, true),
            severity: severity,
            isSelected: isSelected,
        });
        markerRef.current.setIcon(icon);

        if (clusterGroupRef.current && markerRef.current) {
            clusterGroupRef.current.refreshClusters(markerRef.current);
        }
    }, [device, deviceState, isSelected, tooltipPosition]);

    return <></>;
}

function Device({ classes, device, clusterGroupRef, clusterDisabled }) {
    return (
        <MapItem
            item={device}
            clusterGroupRef={clusterGroupRef}
            clusterDisabled={clusterDisabled}
            onRenderPopup={(setOpen, setDeviceDimensions, dragging, setDragging, open) => {
                return (
                    <SingleDeviceView
                        device={device}
                        setOpen={setOpen}
                        onDimensionsChange={(dimensions) => setDeviceDimensions(dimensions)}
                        onClickCapture={(e) => {
                            if (dragging) {
                                e.stopPropagation();
                                setDragging(false);
                            }
                        }}
                        open={open}
                    />
                );
            }}
            onRenderMarker={(markerRef, clusterGroupRef, tooltipPosition, clusterDisabled) => {
                return <MarkerState device={device} markerRef={markerRef} classes={classes} clusterGroupRef={clusterGroupRef} tooltipPosition={tooltipPosition} clusterDisabled={clusterDisabled} />;
            }}
            onRenderTooltip={(tooltipDirection) => <TooltipView device={device} tooltipDirection={tooltipDirection} />}
        />
    );
}

function MapDevices({ devices, clusterGroupRef, markersInitialized, clusterDisabled, classes }) {
    const map = useMap();
    const devicesHidden = useDevicesHidden();

    useEffect(() => {
        if (devices && devices.length > 0) {
            map.fitBounds([devices.filter((device) => device.lat && device.lon).map((device) => [device.lat, device.lon])], { padding: [15, 15] });
        }
    }, [devices, clusterDisabled]);

    if (devicesHidden) {
        return [];
    } else {
        return devices.map(
            (device) =>
                device.lat && (
                    <Device key={"device:" + device.id} device={device} clusterGroupRef={clusterGroupRef} markersInitialized={markersInitialized} clusterDisabled={clusterDisabled} classes={classes} />
                )
        );
    }
}

function ZoomEventListener() {
    const map = useMap();
    const store = useStore();

    const onZoomChanged = (zoom) => {
        console.log("Zoom changed to " + zoom);
        setInsufficientNiraZoom(store, zoom < 9);
    };

    useMapEvents({
        zoomend: (evt) => {
            onZoomChanged(evt.target._zoom);
        },
    });

    return <></>;
}

export function MapView({}) {
    const classes = useStyles();
    const mapIconClasses = useMapIconStyles();
    const devices = useDeviceDataActiveViewDevices();
    const store = useStore();
    const markerRef = useRef();
    const mapContainerRef = useRef();
    const theme = useTheme();
    const location = useLocation();
    const [markersInitialized, setMarkersInitialized] = useState(false);
    const hasNiraPermission = useHasOneOfPermission({ permissions: NiraPerms });

    const niraData = useNiraData();
    const defaultNiraField = useViewDefaultNiraField();
    const mapType = useSelectedMapType();
    const [disabledAggregation] = useDisabledAggregation();

    useEffect(() => {
        if (mapContainerRef.current) {
            mapContainerRef.current._container.style.background = mapType === DarkMap ? "#1C2930" : "#ededed";
        }
    }, [mapContainerRef, mapType]);

    useEffect(() => {
        const params = queryString.parse(location.search.slice(1));

        const enabled = params.radarHistory && parseInt(params.radarHistory, 10) === 1;
        setImageOverlayEnabled(store, enabled);

        return () => {
            setSelectedItem(store, {});
        };
    }, []);

    useEffect(() => {
        setSelectedDataType(store, defaultNiraField);
        if (hasNiraPermission && defaultNiraField !== None) {
            setNiraDataPanelEnabled(store, true);
        }
    }, [defaultNiraField]);

    return (
        <div className={classes.root}>
            <div className={classes.rootColumn}>
                <RadarForecastView niraData={niraData} />
                <MapContainer minZoom={3} maxZoom={18} className={classes.map} whenCreated={() => setMarkersInitialized(true)} fadeAnimation={false} ref={mapContainerRef} zoomControl={false}>
                    <RadarImageOverlay />
                    {hasNiraPermission && <NiraAlertsLayer />}
                    <ZoomControl position={isMobile ? "bottomright" : "topleft"} />
                    <BaseTileLayer />
                    {hasNiraPermission && <VectorGrid niraData={niraData} />}
                    <MarkerClusterGroup
                        animate={false}
                        spiderfyDistanceMultiplier={4}
                        showCoverageOnHover={true}
                        spiderfyOnMaxZoom={true}
                        iconCreateFunction={(cluster) => createClusterCustomIcon(cluster, mapIconClasses)}
                        onMarkerClick={(marker) => setSelectedItem(store, marker.layer.options.device)}
                        ref={markerRef}
                        maxClusterRadius={disabledAggregation ? 0 : 40}
                        spiderLegPolylineOptions={{ weight: 3, color: theme.palette.background.default, opacity: 1 }}
                    >
                        <MapDevices devices={devices} clusterGroupRef={markerRef} markersInitialized={markersInitialized} clusterDisabled={disabledAggregation} classes={classes} />
                    </MarkerClusterGroup>
                    <ZoomEventListener />
                    <MapViewConfigProvider />
                    <HideDevicesPanel />
                </MapContainer>
                <BottomDetailView />
            </div>
        </div>
    );
}
