import { useIntervalWhen } from "rooks";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { startUsingHistoryData, stopUsingHistoryData, useHistoryGetData } from "./hooks/server";
import _ from "loadsh";
import { useStore } from "react-redux";

import { useIsActive } from "./hooks/historyBrowsing";
import { setHistoryBrowsingActive } from "./actions/historyBrowsing";

export function useHistoryDate() {
    const historyActive = useIsActive();
    const store = useStore();
    const [date, setDate] = useState(null);
    const [refreshCurrentTime, setRefreshCurrentTime] = useState(false);
    const historyGetData = useHistoryGetData();

    useEffect(() => {
        setDate(moment.now());

        return () => {
            resetHistory();
        };
    }, []);

    useEffect(() => {
        if (date && historyActive) {
            startUsingHistoryData(store, date);
        }
    }, [date]);

    useIntervalWhen(
        () => {
            if (refreshCurrentTime && !historyActive && moment(date).minute() !== moment().minute()) {
                setDate(moment.now());
            }
        },
        10000,
        true,
        true
    );

    const handleDateChange = (date) => {
        const now = moment();
        if (moment(date).isAfter(now)) {
            resetHistory();
        } else {
            setDate(date);
            setHistoryBrowsingActive(store, true);
        }
    };

    const resetHistory = () => {
        setDate(moment.now());
        setHistoryBrowsingActive(store, false);
        stopUsingHistoryData(store);
    };

    const onHistoryExpiration = () => {
        resetHistory();
    };

    useEffect(() => {
        if (_.isEmpty(historyGetData)) {
            resetHistory();
        } else {
            handleDateChange(historyGetData);
        }
    }, [historyGetData]);

    return {
        historyActive,
        date,
        onHistoryExpiration,
        handleDateChange,
        resetHistory,
        setRefreshCurrentTime,
    };
}
