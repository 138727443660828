import { DialogTitle, useTheme } from "@material-ui/core";
import _ from "loadsh";
import { DataGrid } from "@material-ui/data-grid";
import React, { useMemo } from "react";

import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBinoculars } from "@fortawesome/pro-solid-svg-icons";
import { formatTime } from "../../../Utils/Data/Time";
import { filterRnrStates, useRnrTrafficState } from "../../../Utils/Data/actions/RnrData";
import { AlertHistoryDialogWrapper, AlertHistoryDialogWrapperContent, DataGridProps, translateCols, useAlertHistoryStyles } from "../../../Components/Charts/AlertHistoryDialogWrapper";
import { formatForId } from "../../../Utils/Lang/IntlHelper";
import LoadingScope from "../../../Components/LoadingScope";
import { NoDataMessage } from "../../../Components/Forms/NoDataMessage";
import { formatRnrState } from "./RnrDeviceTooltip";

function useColumns(intl) {
    return useMemo(
        () =>
            translateCols(intl, "rnrProjectErrors.cols.", [
                {
                    field: "message",
                    valueFormatter: (row) => formatRnrState(intl, row.row),
                    sortable: true,
                    flex: 1,
                },
                {
                    field: "first_occurrence_time",
                    valueFormatter: (row) => formatTime(row.value),
                    type: "dateTime",
                    sortable: true,
                    flex: 1,
                },
                {
                    field: "last_occurrence_time",
                    valueFormatter: (row) => formatTime(row.value),
                    type: "dateTime",
                    sortable: true,
                    flex: 1,
                },
                {
                    field: "count",
                    sortable: true,
                    flex: 1,
                },
            ]),
        [intl]
    );
}

export function RnrProjectErrorsDialog({ open, onClose, entity }) {
    const intl = useIntl();
    const classes = useAlertHistoryStyles();
    const columns = useColumns(intl);
    const theme = useTheme();

    const { rnrTrafficStateData, loading } = useRnrTrafficState(entity?.rnrProject?.dataSource);

    const states = useMemo(() => {
        return filterRnrStates(rnrTrafficStateData?.project_errors);
    }, [rnrTrafficStateData]);

    return (
        <AlertHistoryDialogWrapper open={open} onClose={onClose} fullWidth={true} scroll={"paper"} fullScreen={true}>
            <DialogTitle>
                <div className={classes.root}>
                    <FontAwesomeIcon icon={faBinoculars} size={"lg"} style={{ paddingRight: theme.spacing(1) }} />
                    {formatForId(intl, "pages.appBar.tooltip.rnrActiveErrors")}
                </div>
            </DialogTitle>
            <AlertHistoryDialogWrapperContent onClose={onClose}>
                <LoadingScope loading={loading}>
                    {_.isEmpty(rnrTrafficStateData) ? (
                        <NoDataMessage />
                    ) : (
                        <DataGrid
                            {...DataGridProps}
                            classes={classes}
                            columns={columns}
                            rows={states}
                            pagination
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                            componentsProps={{
                                pagination: {
                                    labelRowsPerPage: formatForId(intl, "dataGrid.pagination.rowsPerPage"),
                                    labelDisplayedRows: ({ from, to, count }) =>
                                        formatForId(intl, "dataGrid.pagination.displayedRows", {
                                            from,
                                            to,
                                            count,
                                        }),
                                },
                            }}
                        />
                    )}
                </LoadingScope>
            </AlertHistoryDialogWrapperContent>
        </AlertHistoryDialogWrapper>
    );
}
