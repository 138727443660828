import { combineReducers } from "redux";
import server from "./server";
import masterDetail from "./masterDetail";
import gui from "./gui";
import dataList from "./dataList";
import meteogram from "./meteogram";
import deviceDataView from "./deviceDataView";
import map from "./map";
import historyBrowsing from "./historyBrowsing";
import { reduxEventReducer } from "react-redux-events";

export default combineReducers({
    server,
    masterDetail,
    gui,
    dataList,
    reduxEventReducer,
    meteogram,
    deviceDataView,
    map,
    historyBrowsing,
});
