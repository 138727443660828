import { useEndDeviceData, useRnrData } from "../../../Utils/Data/actions/RnrData";
import { useTheme } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import _ from "lodash";
import { DeviceStateTooltip, RnrDeviceTooltip } from "./RnrDeviceTooltip";

export function RnrItem({ position, rnrDevice, rnrTrafficStateData, refreshTrigger, tooltipColor }) {
    const rnrState = useRnrData(rnrDevice, rnrTrafficStateData);
    const theme = useTheme();

    const disabled = !rnrDevice?.systemDevice?.enabled;
    const hasError = rnrState?.errors?.is_last_active || disabled;
    const hasWarning = rnrState?.warnings?.is_last_active;

    const hasIoState = (statesId) => {
        for (const ioDevice of rnrDevice?.ioDevices) {
            const states = rnrState?.state?.current_state?.slices?.[ioDevice.sliceId]?.[ioDevice.endDeviceId]?.[statesId]?.histogram;
            if (states) {
                for (const state of Object.values(states)) {
                    if (state.is_active) {
                        return true;
                    }
                }
            }
        }

        return false;
    };

    const backgroundImage = useMemo(() => {
        let backrgroundImage = "";

        const hasInfoIo = hasIoState("infos");
        const hasWarningIo = hasIoState("warnings");
        const hasErrorIo = hasIoState("errors");

        if (hasError) {
            backrgroundImage = "RNR_comm_lost.svg";
        } else if (hasWarningIo || hasErrorIo) {
            backrgroundImage = "RNR_error.svg";
        } else if (hasWarning || hasInfoIo) {
            backrgroundImage = "RNR_door_open.svg";
        } else {
            backrgroundImage = "RNR_OK.svg";
        }
        return backrgroundImage;
    }, [disabled, hasError, hasWarning, theme, rnrState]);

    if (_.isEmpty(position)) {
        return null;
    }

    return (
        <RnrDeviceTooltip rnrState={rnrState} rnrDevice={rnrDevice} trafficStatesData={rnrTrafficStateData} tooltipColor={tooltipColor}>
            <div
                style={{
                    position: position.position,
                    left: position.x,
                    top: position.y,
                    width: position.width,
                    height: position.height,
                }}
            >
                <img src={`/rnr-icon/${backgroundImage}`} alt={backgroundImage} style={{ width: "100%", height: "100%" }} />
            </div>
        </RnrDeviceTooltip>
    );
}

function Warning({ position, symbols }) {
    if (_.isEmpty(symbols)) {
        return null;
    }
    return (
        <div
            style={{
                position: position.position,
                top: 0,
                left: 0,
                width: position.width,
                height: position.height,
            }}
        >
            <SymbolArea displayedSymbols={symbols} position={position} backgroundColor={"unset"} />
        </div>
    );
}

function Image({ src, alt, style }) {
    const [error, setError] = useState(false);

    if (error) {
        return <img src={`/rnr-icon/missing_symbol.svg`} alt={alt} style={style} />;
    } else {
        return <img src={src} alt={alt} style={style} onError={(e) => setError(true)} />;
    }
}

function SymbolArea({ displayedSymbols, position, device, hasError, backgroundColor }) {
    const displayedSymbolsComputed = useMemo(() => {
        if (hasError) {
            return ["comm_lost"];
        } else if (_.isEmpty(displayedSymbols)) {
            return [];
        } else if (position.rotation === 90) {
            return displayedSymbols.slice().reverse();
        }
        return displayedSymbols;
    }, [displayedSymbols, position.rotation]);

    return (
        <div
            style={{
                display: "flex",
                backgroundColor,
                flexDirection: position.isHorizontal ? "column" : "row",
                width: position.width,
                height: position.height,
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {displayedSymbolsComputed.map((symbol) => (
                <div
                    style={{
                        width: position.isHorizontal ? position.width / displayedSymbolsComputed.length : position.width,
                        height: position.isHorizontal ? position.height : position.height / displayedSymbolsComputed.length,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Image
                        src={`/rnr-icon/${symbol}.svg`}
                        alt={device?.id}
                        style={{
                            transform: `rotate(${position.rotation}deg)`,
                            height: position.isHorizontal ? "100%" : position.width / displayedSymbolsComputed.length,
                            width: position.isHorizontal ? "100%" : position.height / displayedSymbolsComputed.length,
                        }}
                    />
                </div>
            ))}
        </div>
    );
}

export function RnrEndDevice({ position, device, rnrTrafficStateData, symbolOverride, tooltipColor }) {
    const { rnrState, endDeviceState } = useEndDeviceData(device, rnrTrafficStateData);
    const { displayedSymbols, previewInfo } = useMemo(() => {
        let displayedSymbols = [];
        let previewInfo = null;

        if (symbolOverride) {
            const ret = symbolOverride.find((s) => s.id === device.id);
            displayedSymbols = ret?.symbols || ["without_change"];
            previewInfo = ret;
        } else {
            displayedSymbols = endDeviceState?.current_display?.symbols || [];
        }
        return { displayedSymbols, previewInfo };
    }, [symbolOverride, endDeviceState]);

    const warningSymbols = useMemo(() => {
        if (symbolOverride) {
            if (previewInfo?.isMandatory) {
                return ["mandatory"];
            }
        } else if (!endDeviceState?.hasCriticalError) {
            if (endDeviceState?.warnings?.is_last_active) {
                return ["error_frame"];
            } else if (endDeviceState?.infos?.is_last_active && endDeviceState?.infos?.last?.message === "door_open") {
                return ["warning_frame"];
            }
        }
        return undefined;
    }, [displayedSymbols, previewInfo, endDeviceState, position]);

    if (_.isEmpty(position)) {
        return null;
    }

    return (
        <>
            <DeviceStateTooltip device={device} deviceState={endDeviceState} rnrState={rnrState} previewInfo={previewInfo} tooltipColor={tooltipColor}>
                <div
                    style={{
                        position: position.position,
                        left: position.x,
                        top: position.y,
                        width: position.width,
                        height: position.height,
                    }}
                >
                    <SymbolArea displayedSymbols={displayedSymbols} position={position} device={device} hasError={_.isEmpty(symbolOverride) && endDeviceState.hasError} backgroundColor={"black"} />
                    <Warning position={position} symbols={warningSymbols} />
                </div>
            </DeviceStateTooltip>
        </>
    );
}
