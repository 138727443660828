import { Marker, Tooltip, useMap, useMapEvents } from "react-leaflet";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useViewConfig } from "../../../Utils/Data/hooks/deviceDataView";
import L from "leaflet";
import _ from "loadsh";
import CustomPopup from "../../../Components/Map/EditablePopup";
import { BASE_Y_POPUP_OFFSET, SELECTED_COLOR } from "../../../Components/Map/MapUtils";
import { useIntervalWhen } from "rooks";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import * as ReactDOMServer from "react-dom/server";

export const defaultTop = { direction: "top", offset: [0, -25], popupOffset: [-120, BASE_Y_POPUP_OFFSET] };
export const left = { direction: "left", offset: [-25, 0], popupOffset: [-250, BASE_Y_POPUP_OFFSET] };
export const right = { direction: "right", offset: [25, 0], popupOffset: [30, BASE_Y_POPUP_OFFSET] };
export const bottom = { direction: "bottom", offset: [0, 25], popupOffset: [-120, 250] };

export const useMapIconStyles = makeStyles((theme) => ({
    icon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        color: "#ededed",
    },
    clusterDisabledIcon: {
        width: 20,
        height: 20,
    },
    smallIcon: {
        width: 30,
        height: 30,
    },
    largeIcon: {
        width: 40,
        height: 40,
    },
    clusterDisabledIconUnselected: {
        border: "1px solid black",
    },
    iconUnselected: {
        border: "2px solid black",
    },
    iconSelected: {
        border: "3px solid " + SELECTED_COLOR,
    },
    icon_0: {
        background: theme.palette.success.main,
    },
    icon_1: {
        background: theme.palette.warnings.level_1.primary,
        color: theme.palette.warnings.level_1.textColor,
    },
    icon_2: {
        background: theme.palette.warnings.level_2.primary,
        color: theme.palette.warnings.level_2.textColor,
    },
    icon_3: {
        background: theme.palette.warnings.level_3.primary,
        color: theme.palette.warnings.level_3.textColor,
    },
    icon_100: {
        background: theme.palette.warnings.level_100.primary,
        color: theme.palette.warnings.level_100.textColor,
    },
    icon_30: {
        background: theme.palette.warnings.level_30.primary,
        color: theme.palette.warnings.level_30.textColor,
    },
}));

export function computeTooltipDirection(map, position, offset = 250) {
    const isMarkerInView = map.getBounds().contains(position);

    if (isMarkerInView) {
        const west = map.latLngToLayerPoint(map.getBounds().getSouthWest()).x;
        const east = map.latLngToLayerPoint(map.getBounds().getSouthEast()).x;
        const north = map.latLngToLayerPoint(map.getBounds().getNorthEast()).y;
        const item = map.latLngToLayerPoint(position).x;
        const itemY = map.latLngToLayerPoint(position).y;

        const isLeft = Math.abs(west - item) > offset && Math.abs(east - item) < offset;
        const isRight = Math.abs(west - item) < offset && Math.abs(east - item) > offset;
        const isBottom = Math.abs(north - itemY) < offset;

        if (isLeft) {
            return left;
        } else if (isRight) {
            return right;
        } else if (isBottom) {
            return bottom;
        }
    }
    return defaultTop;
}

export function CustomClusterIcon({ cluster, classes, severity, isSelected }) {
    const iconClass = cluster.getChildCount() < 100 ? classes.smallIcon : classes.largeIcon;
    //XXX do not use material here, it breaks styles!!

    return (
        <div className={clsx(classes.icon, iconClass, classes[`icon_${severity}`], isSelected ? classes.iconSelected : classes.iconUnselected)}>
            <div style={{ fontSize: "0.925rem", fontWeight: 500 }}>{cluster.getChildCount()}</div>
        </div>
    );
}

export function MapViewConfigProvider({}) {
    const viewConfig = useViewConfig();
    const map = useMap();

    useEffect(() => {
        if (_.isEmpty(viewConfig) || !_.isEmpty(viewConfig?.data_query?.filter)) {
            return;
        }

        const viewCustomFields = JSON.parse(viewConfig.gui_custom_field ? viewConfig.gui_custom_field : "{}");

        const zoom = viewCustomFields.map_zoom ? viewCustomFields.map_zoom : 9;
        const lat = viewCustomFields.map_lat ? viewCustomFields.map_lat : 48.148598;
        const lon = viewCustomFields.map_lon ? viewCustomFields.map_lon : 17.107748; // bratislava is default
        map.setView(new L.LatLng(lat, lon), zoom);
    }, [viewConfig, map]);

    return <></>;
}

export function MapItem({ item, clusterGroupRef, clusterDisabled, onRenderPopup, onRenderMarker, onRenderTooltip, standalonePopup = false }) {
    const initialPosition = useMemo(() => [item.lat, item.lon], [item]);

    const [open, setOpen] = useState(false);
    const [position, setPosition] = useState(initialPosition);
    const [dragging, setDragging] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState(defaultTop);
    const [popupOffset, setPopupOffset] = useState(defaultTop.popupOffset);
    const [positionRefresh, setPositionRefresh] = useState(true);
    const markerRef = useRef();
    const [deviceDimensions, setDeviceDimensions] = useState({ width: 150, height: 150 });

    useMapEvents({
        moveend: (evt) => {
            const map = evt.target;
            setTooltipPosition(computeTooltipDirection(map, initialPosition, clusterDisabled ? 180 : 250));
        },
    });

    useEffect(() => {
        markerRef.current?.off("click");
        markerRef.current?.on("dblclick", () => (open ? setOpen(false) : markerRef.current?.openPopup()));
        return () => markerRef.current?.off("dblclick");
    }, [markerRef.current, open]);

    useEffect(() => {
        if (markerRef.current) {
            const marker = markerRef.current;
            if (standalonePopup) {
                setPopupOffset([0, 0]);
                marker._tooltip.options.direction = tooltipPosition.direction;
            } else if (!open && marker?._tooltip?.options) {
                if (clusterDisabled) {
                    marker._tooltip.options.offset = [tooltipPosition.offset[0] / 5, tooltipPosition.offset[1] / 5];
                } else {
                    marker._tooltip.options.offset = tooltipPosition.offset;
                }
                marker._tooltip.options.direction = tooltipPosition.direction;
                setPopupOffset(tooltipPosition.popupOffset);
            }
        }
    }, [tooltipPosition, open, item, standalonePopup]);

    useIntervalWhen(() => markerRef.current?.openPopup(), 100, standalonePopup);

    return (
        <Marker
            position={initialPosition}
            eventHandlers={{
                popupopen: (e) => {
                    setOpen(true);
                },
                popupclose: (e) => {
                    setOpen(false);
                },
            }}
            device={item}
            ref={markerRef}
        >
            {!clusterDisabled && (
                <CustomPopup
                    open={open}
                    removable={!standalonePopup}
                    editable={false}
                    closeOnClick={false}
                    autoClose={false}
                    autoPan={false}
                    autoPanPadding={[20, 20]}
                    popupPos={position}
                    initialPosition={initialPosition}
                    markerRef={markerRef}
                    onPositionChange={setPosition}
                    onDragStart={() => setDragging(true)}
                    popupDimensions={deviceDimensions}
                    offset={popupOffset}
                    positionRefresh={positionRefresh}
                    internactive={!standalonePopup}
                    draggable={!standalonePopup}
                >
                    {onRenderPopup(setOpen, setDeviceDimensions, dragging, setDragging, open)}
                </CustomPopup>
            )}
            <Tooltip>{onRenderTooltip(tooltipPosition.direction)}</Tooltip>
            {onRenderMarker ? onRenderMarker(markerRef, clusterGroupRef, tooltipPosition, clusterDisabled) : <EmptyMarkerState markerRef={markerRef} clusterGroupRef={clusterGroupRef} />}
        </Marker>
    );
}

function EmptyMarkerState({ markerRef, clusterGroupRef }) {
    useEffect(() => {
        const icon = L.divIcon({
            html: ReactDOMServer.renderToString(<div></div>),
        });
        markerRef.current.setIcon(icon);

        if (clusterGroupRef.current && markerRef.current) {
            clusterGroupRef.current.refreshClusters(markerRef.current);
        }
    }, [markerRef]);

    return <></>;
}
