import React, { useMemo } from "react";
import { IconButton, Tooltip, useTheme } from "@material-ui/core";
import { useIntl } from "react-intl";
import Badge from "@material-ui/core/Badge";
import { useActiveAlertCounter, useActiveGeoAlertCounter, useIsHistoryActive } from "../../Utils/Data/hooks/server";
import { makeStyles } from "@material-ui/styles";
import { formatForId } from "../../Utils/Lang/IntlHelper";
import { getAlertColor, getNiraAlertColor } from "../../Utils/Data/AlertFormatter";
import useWarningSound, { useGeoWarningSound } from "../WarningAlertPlayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faCircleExclamation, faSensorAlert } from "@fortawesome/pro-solid-svg-icons";
import { useStore } from "react-redux";
import { useViewCustomConfig } from "../../Utils/Data/hooks/deviceDataView";
import { openDialog } from "../../Utils/Data/actions/gui";
import _ from "lodash";
import { filterRnrStates, useRnrTrafficState } from "../../Utils/Data/actions/RnrData";

const useStyles = makeStyles({
    badge: {
        backgroundColor: (props) => props.color.primary,
        color: (props) => props.color.textColor,
    },
});

function CustomTooltip({ intlId, alertCount, children }) {
    const intl = useIntl();
    if (alertCount === 0) {
        return <Tooltip title={formatForId(intl, intlId)}>{children}</Tooltip>;
    } else {
        return (
            <Tooltip
                title={
                    <span>
                        {formatForId(intl, intlId)}
                        <br />
                        {formatForId(intl, "pages.appBar.tooltip.unconfirmedAlerts")}
                    </span>
                }
            >
                {children}
            </Tooltip>
        );
    }
}

export function ActiveAlerts({ onClick }) {
    const warnings = useActiveAlertCounter();
    const theme = useTheme();

    const color = useMemo(() => getAlertColor(warnings.maxLevel, theme), [warnings]);

    const classes = useStyles({ color });

    useWarningSound();

    const historyActive = useIsHistoryActive();

    const alertCountComputed = useMemo(() => (historyActive ? 0 : warnings.notConfirmedActiveAlerts), [warnings, historyActive]);

    return (
        <CustomTooltip alertCount={alertCountComputed} intlId={"pages.appBar.tooltip.alerts"}>
            <IconButton onClick={onClick}>
                <Badge badgeContent={alertCountComputed} classes={classes}>
                    <FontAwesomeIcon icon={faCircleExclamation} color={historyActive ? theme.palette.historyActiveColor : ""} />
                </Badge>
            </IconButton>
        </CustomTooltip>
    );
}

export function ActiveGeoAlerts({ onClick }) {
    const warnings = useActiveGeoAlertCounter();
    const theme = useTheme();

    const color = useMemo(() => getNiraAlertColor(warnings.maxLevel, theme), [warnings]);

    const classes = useStyles({ color });

    useGeoWarningSound();

    const historyActive = useIsHistoryActive();

    const alertCountComputed = useMemo(() => (historyActive ? 0 : warnings.notConfirmedActiveGeoAlerts), [warnings, historyActive]);

    return (
        <CustomTooltip alertCount={alertCountComputed} intlId={"pages.appBar.tooltip.geoAlerts"}>
            <IconButton onClick={onClick}>
                <Badge badgeContent={alertCountComputed} classes={classes}>
                    <FontAwesomeIcon icon={faCar} color={historyActive ? theme.palette.historyActiveColor : ""} />
                </Badge>
            </IconButton>
        </CustomTooltip>
    );
}

export function ActiveRnrProjectErrors({ onClick }) {
    const viewConfig = useViewCustomConfig();

    if (_.isEmpty(viewConfig?.rnr_project)) {
        return null;
    }

    return <ActiveRnrProjectErrorsLocal onClick={onClick} rnrProject={viewConfig.rnr_project} />;
}

function ActiveRnrProjectErrorsLocal({ onClick, rnrProject }) {
    const theme = useTheme();
    const store = useStore();
    const classes = useStyles({ color: theme.palette.warnings.level_3 });

    const { rnrTrafficStateData, reloadData, error, loading } = useRnrTrafficState(rnrProject.dataSource);

    const states = useMemo(() => {
        return filterRnrStates(rnrTrafficStateData?.project_errors);
    }, [rnrTrafficStateData]);

    if (states.length === 0) {
        return null;
    }

    return (
        <CustomTooltip alertCount={0} intlId={"pages.appBar.tooltip.rnrActiveErrors"}>
            <IconButton onClick={() => openDialog(store, { type: "rnrProjectErrors", entity: { rnrProject } })}>
                <Badge badgeContent={states.length} classes={classes}>
                    <FontAwesomeIcon icon={faSensorAlert} />
                </Badge>
            </IconButton>
        </CustomTooltip>
    );
}
