import React, { useEffect, useMemo, useState } from "react";
import { useActiveView, useViewCustomConfig } from "../../../Utils/Data/hooks/deviceDataView";
import { useBackgroundImage, useSliceDelay } from "../../../Utils/Data/actions/RnrData";
import _ from "lodash";
import LoadingScope from "../../../Components/LoadingScope";
import moment from "moment";
import { useIntervalWhen } from "rooks";
import { RnrEndDevice, RnrItem } from "./RnrComponents";

function isHorizontal(rotation) {
    return Math.abs(rotation) === 0 || Math.abs(rotation) === 180;
}

function getElementPosition(backgroundElem, elemId) {
    const elem = backgroundElem.querySelector(`#${elemId}`);
    if (!elem) {
        return;
    }
    const svg = elem.ownerSVGElement;

    const pt = svg.createSVGPoint();

    pt.x = elem.x.baseVal.value;
    pt.y = elem.y.baseVal.value;

    const pt2 = svg.createSVGPoint();
    pt2.x = elem.x.baseVal.value + elem.width.baseVal.value;
    pt2.y = elem.y.baseVal.value + elem.height.baseVal.value;

    const ptTransformed = pt.matrixTransform(elem.getScreenCTM());
    const pt2Transformed = pt2.matrixTransform(elem.getScreenCTM());

    let x = 0,
        y = 0,
        width = 0,
        height = 0;

    if (pt2Transformed.x < ptTransformed.x) {
        x = pt2Transformed.x;
        width = ptTransformed.x - pt2Transformed.x;
    } else {
        x = ptTransformed.x;
        width = pt2Transformed.x - ptTransformed.x;
    }

    if (pt2Transformed.y < ptTransformed.y) {
        y = pt2Transformed.y;
        height = ptTransformed.y - pt2Transformed.y;
    } else {
        y = ptTransformed.y;
        height = pt2Transformed.y - ptTransformed.y;
    }

    let rotation = 0;

    for (let i = 0; i < elem.transform.baseVal.numberOfItems; i++) {
        const item = elem.transform.baseVal.getItem(i);
        if (item.type === SVGTransform.SVG_TRANSFORM_ROTATE) {
            rotation = item.angle;
        }
    }

    return { position: "absolute", x, y, width, height, rotation, isHorizontal: isHorizontal(rotation) };
}

function usePosition(elemId, backgroundElemRef, refreshTrigger) {
    const [position, setPosition] = useState({});
    useEffect(() => {
        const handleResize = () => {
            if (backgroundElemRef.current) {
                setPosition(getElementPosition(backgroundElemRef.current, elemId));
            }
        };

        window.addEventListener("resize", handleResize);

        const timeout = setTimeout(() => {
            handleResize();
        }, 100);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
            clearTimeout(timeout);
        };
    }, [backgroundElemRef, elemId, refreshTrigger]);

    return position;
}

function RnrItemCustom({ backgroundElemRef, rnrDevice, rnrTrafficStateData, refreshTrigger }) {
    const position = usePosition(rnrDevice.id, backgroundElemRef, refreshTrigger);
    const viewConfig = useViewCustomConfig();
    return <RnrItem rnrDevice={rnrDevice} position={position} rnrTrafficStateData={rnrTrafficStateData} refreshTrigger={refreshTrigger} tooltipColor={viewConfig?.tooltip_color} />;
}

function Item({ backgroundElemRef, device, rnrTrafficStateData, symbolOverride, refreshTrigger }) {
    const position = usePosition(device.id, backgroundElemRef, refreshTrigger);
    const viewConfig = useViewCustomConfig();

    return (
        <RnrEndDevice
            device={device}
            refreshTrigger={refreshTrigger}
            position={position}
            rnrTrafficStateData={rnrTrafficStateData}
            symbolOverride={symbolOverride}
            tooltipColor={viewConfig?.tooltip_color}
        />
    );
}

function CountdownItem({ backgroundElemRef, rnrTrafficStateData, slice, symbolsOverride, refreshTrigger }) {
    const position = usePosition(`${slice.id}-countdown`, backgroundElemRef, refreshTrigger);
    const countdownData = useSliceDelay(slice, rnrTrafficStateData);

    const countdownDataComputed = useMemo(() => {
        if (_.isEmpty(symbolsOverride)) {
            return countdownData;
        } else {
            return symbolsOverride.find((item) => item.nrId === slice.id)?.delay;
        }
    }, [symbolsOverride, countdownData]);

    if (!countdownDataComputed) {
        return null;
    }

    return (
        <div
            style={{
                position: "absolute",
                left: position.x,
                top: position.y,
                width: position.width,
                height: position.height,
            }}
        >
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "0.68rem",
                    fontWeight: "500",
                }}
            >
                {moment.utc(countdownDataComputed).format("mm:ss")}
            </div>
        </div>
    );
}

export function SvgView({ endDevices, rnrTrafficStateData, symbolsOverride, rnrDevices = [], fillParent = true, refreshTrigger = null, slices }) {
    const ref = React.useRef(null);
    const view = useActiveView();
    const backgroundImage = useBackgroundImage(view);

    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        setRefresh(!refresh);
    }, [refreshTrigger]);

    useIntervalWhen(
        () => {
            setRefresh(!refresh);
        },
        10000,
        true,
        true
    );

    return (
        <>
            <LoadingScope loading={!backgroundImage?.data} showLoading={true} dialog={false}>
                {backgroundImage?.data && (
                    <div
                        ref={ref}
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: fillParent ? "flex-end" : "center",
                            alignItems: fillParent ? "center" : "unset",
                        }}
                        dangerouslySetInnerHTML={{ __html: Buffer.from(backgroundImage.data, "base64") }}
                    />
                )}

                {endDevices
                    .filter((device) => device?.endDevice?.type === "znp")
                    .map((device) => (
                        <Item device={device} backgroundElemRef={ref} rnrTrafficStateData={rnrTrafficStateData} symbolOverride={symbolsOverride} refreshTrigger={refresh} />
                    ))}

                {rnrDevices.map((rnrDevice) => (
                    <RnrItemCustom rnrDevice={rnrDevice} backgroundElemRef={ref} rnrTrafficStateData={rnrTrafficStateData} refreshTrigger={refresh} />
                ))}

                {slices?.map((slice) => (
                    <CountdownItem slice={slice} backgroundElemRef={ref} rnrTrafficStateData={rnrTrafficStateData} symbolsOverride={symbolsOverride} refreshTrigger={refresh} />
                ))}
            </LoadingScope>
        </>
    );
}
