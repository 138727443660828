import React from "react";
import { MapView } from "./MapView";
import { ViewToolbar } from "./ViewToolbar";
import { ColumnView } from "./ColumnView";
import { useDevicesData, useViewDevicesData } from "../../Utils/Data/hooks/server";
import { makeStyles } from "@material-ui/core/styles";
import { TableView } from "./TableView";
import { useGetDomainGroup } from "../../Utils/Data/hooks/urlNavigation";
import { Redirect, Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { setCustomAppBar } from "../../Utils/Data/actions/gui";
import { useStore } from "react-redux";
import _ from "loadsh";
import { NoDataMessage } from "../../Components/Forms/NoDataMessage";
import { LoadingScopeForActiveView } from "../../Components/LoadingScope";
import { CamerasView } from "./CamerasView";
import { setActiveDeviceDataView, setActiveDeviceDataViewLoadingState } from "../../Utils/Data/actions/deviceDataView";
import { useDeviceDataActiveViewDevices, useDeviceDataActiveViewLoadingState } from "../../Utils/Data/hooks/deviceDataView";
import { DeviceCameraView } from "./DeviceCameraView";
import RnrView from "./Rnr/RnrView";
import DebugRnrView from "./Rnr/DebugRnrView";
import { RnrAuditLog } from "./Rnr/RnrAuditLog";
import RnrMapView from "./Rnr/RnrMapView";

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    noData: {
        padding: theme.spacing(1),
    },
}));

function EmptyDevicesHandler({ classes, children }) {
    const devices = useDeviceDataActiveViewDevices();
    const { loading } = useDeviceDataActiveViewLoadingState();

    if (loading) {
        return null;
    }

    if (_.isEmpty(devices) && !loading) {
        return (
            <div className={classes.noData}>
                <NoDataMessage />
            </div>
        );
    }
    return children;
}

export function DomainGroupDevicesView() {
    const { domainId, groupId } = useGetDomainGroup();
    const [devices, loading, error, reload] = useDevicesData(domainId, groupId);
    const store = useStore();

    React.useEffect(() => {
        if (!loading) {
            setActiveDeviceDataView(store, { devices, context: { type: "domain-group", domainId, groupId } });
        }
    }, [devices]);

    React.useEffect(() => {
        reload();
    }, [domainId, groupId]);

    return <Views />;
}

export function GlobalDevicesView() {
    const { id } = useParams();
    const viewId = parseInt(id, 10);
    const store = useStore();

    const [devices, loading, error, reload, config] = useViewDevicesData(viewId);
    React.useEffect(() => {
        if (error && error.status === 403) {
            window.location.href = "/";
            return;
        }

        setActiveDeviceDataViewLoadingState(store, { loading, error });
    }, [loading, error]);

    React.useEffect(() => {
        if (!loading) {
            setActiveDeviceDataView(store, {
                devices,
                context: { type: "view", viewId },
                config: config ? config : {},
            });
        }
    }, [devices]);

    return <Views />;
}

function Views() {
    const classes = useStyles();
    const store = useStore();

    const { path, url } = useRouteMatch();

    React.useEffect(() => {
        setCustomAppBar(store, true);
        return () => setCustomAppBar(store, false);
    }, []);

    return (
        <div className={classes.root}>
            <LoadingScopeForActiveView dialog={true}>
                <Switch>
                    <Redirect path={path} exact={true} to={url} />
                    <Route path={path + "/table/"}>
                        <ViewToolbar />
                        <EmptyDevicesHandler classes={classes}>
                            <TableView />
                        </EmptyDevicesHandler>
                    </Route>
                    <Route path={path + "/map/"}>
                        <ViewToolbar />
                        <MapView />
                    </Route>

                    <Route path={path + "/column/"}>
                        <ViewToolbar />
                        <EmptyDevicesHandler classes={classes}>
                            <ColumnView />
                        </EmptyDevicesHandler>
                    </Route>
                    <Route path={path + "/camera/"}>
                        <ViewToolbar />
                        <EmptyDevicesHandler classes={classes}>
                            <CamerasView />
                        </EmptyDevicesHandler>
                    </Route>
                    <Route path={path + "/device/:deviceId/"}>
                        <DeviceCameraView />
                    </Route>
                    <Route path={path + "/rnrDebug/"}>
                        <ViewToolbar />
                        <DebugRnrView />
                    </Route>
                    <Route path={path + "/rnrMap/"}>
                        <ViewToolbar />
                        <RnrMapView />
                    </Route>
                    <Route path={path + "/rnrBasic/"}>
                        <ViewToolbar />
                        <RnrView />
                    </Route>
                    <Route path={path + "/rnrAuditLog/"}>
                        <ViewToolbar />
                        <RnrAuditLog />
                    </Route>
                </Switch>
            </LoadingScopeForActiveView>
        </div>
    );
}
